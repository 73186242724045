<template>
  <div slot="header" class="headerIndex">
    <div class="container-icons-header">
      <feather-icon v-for="(btn, index) in buttons"  v-bind:key="index"
      :icon="btn.icon"
      @click="update(btn.emitName)"
      class="icon-header"
      size="37"
      :class="{'text-primary stroke-current': currentView === btn.emitName }"/>
      <feather-icon icon="ListIcon" @click="update('list')" class="stroke-current" size="37" :title="$t('Labels.ShowList')" v-b-tooltip.hover="{ variant: 'secondary', placement: 'top' }" :class="{'icon-header-spore text-white': currentView === 'list', 'icon-header text-primary': currentView !== 'list'}"/>
      <feather-icon icon="GridIcon" @click="update('grid')" class="stroke-current" size="37" :title="$t('Labels.ShowGrid')" v-b-tooltip.hover="{ variant: 'secondary', placement: 'top' }" :class="{'icon-header-spore text-white': currentView === 'grid', 'icon-header text-primary': currentView !== 'grid'}"/>
      <feather-icon icon="MapIcon" @click="update('map')" v-if="path !== '/labels'" class="stroke-current" size="37" :title="$t('Labels.ShowMap')" v-b-tooltip.hover="{ variant: 'secondary', placement: 'top' }" :class="{'icon-header-spore text-white': currentView === 'map', 'icon-header text-primary': currentView !== 'map'}"/>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    initView: String,
    buttons: Array
  },
  data () {
    return {
      currentView: 'list',
      path: this.$route.path
    }
  },
  methods: {
    update (type) {
      this.$emit(type)
      this.currentView = type
    }
  },
  created () {
    if (this.initView !== undefined) this.currentView = this.initView
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/headerIndex.scss";
</style>
